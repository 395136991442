<template>
  <header class="header">
    <router-link to="/" class="logo">
      <img
        :src="require('@/assets/images/logo.svg')"
        class="logo"
        alt="Prohada"
      />
    </router-link>
    <div class="header-search">
      <input
        type="text"
        class="form-input header-search__input"
        :placeholder="$t('message.search_code')"
      />
      <div class="header-search__ico">
        <svg
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          aria-labelledby="searchIconTitle"
          stroke="#6c6c6c"
          stroke-width="2"
          stroke-linecap="square"
          stroke-linejoin="miter"
          fill="none"
          color="#2329D6"
        >
          <title id="searchIconTitle">Search</title>
          <path d="M14.4121122,14.4121122 L20,20" />
          <circle cx="10" cy="10" r="6" />
        </svg>
      </div>
    </div>
    <div class="header-profile">
      <div class="header-profile__notification">
        <svg
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          aria-labelledby="bellIconTitle"
        >
          <title id="bellIconTitle">Bell</title>
          <path
            stroke-linejoin="round"
            fill="#434567cc"
            d="M10.5,4.5 C12.1666667,4.5 13.8333333,4.5 15.5,4.5 C17.5,4.5 18.8333333,3.83333333 19.5,2.5 L19.5,18.5 C18.8333333,17.1666667 17.5,16.5 15.5,16.5 C13.8333333,16.5 12.1666667,16.5 10.5,16.5 L10.5,16.5 C7.1862915,16.5 4.5,13.8137085 4.5,10.5 L4.5,10.5 C4.5,7.1862915 7.1862915,4.5 10.5,4.5 Z"
            transform="rotate(90 12 10.5)"
          ></path>
          <path
            fill="black"
            d="M11,21 C12.1045695,21 13,20.1045695 13,19 C13,17.8954305 12.1045695,17 11,17"
            transform="rotate(90 12 19)"
          ></path>
        </svg>
      </div>
      <div class="profile-dropdown__parent">
        <img
          :src="
            profile?.profile_picture
              ? profile.profile_picture
              : require('@/assets/images/profile-pic.png')
          "
          alt="Avatar"
          class="header-profile__pic"
        />
        <ul class="profile-dropdown">
          <li>
            <router-link to="/account/profile">{{
              $t("message.profile")
            }}</router-link>
          </li>
          <li>
            <a>{{ $t("message.settings") }}</a>
          </li>
          <li>
            <a @click="logout">{{ $t("message.logout") }}</a>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import { computed, onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
// import router from '@/router'
export default {
  setup() {
    const store = useStore();
    const profile = computed(() => store.state.account.profile.data);
    async function logout() {
      await store.dispatch("auth/logOut");
      // router.push({ path: '/' });
      window.location.href = "/";
    }
    onMounted(() => {
      if (profile.value == undefined || !profile.value) {
        store.dispatch("account/fetchProfile");
      }
    });
    return {
      logout,
      profile,
    };
  },
};
</script>
