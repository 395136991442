<template>
  <div
    selected_op="selectedadscasd"
    :class="['form-select', state.uiSelectClass == select_dd ? 'active' : '']"
  >
    <label class="t-lbl">{{ select_label }}</label>
    <span
      @click="
        state.uiSelectClass == ''
          ? (state.uiSelectClass = select_dd)
          : (state.uiSelectClass = '')
      "
      :style="'--w: ' + select_width"
      >{{ state.label }} &nbsp; &nbsp; &nbsp; &nbsp;
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-chevron-down"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#597e8d"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <polyline points="6 9 12 15 18 9" />
      </svg>
    </span>
    <ul :class="state.uiSelectClass == select_dd ? 'active' : ''">
      <li v-if="defaultValue != 'false'" @click="selectedOption(null)">
        -- {{ $t("message.all") }} --
      </li>
      <li v-for="(d, key) in data" @click="selectedOption(d)" :key="key">
        {{ d[data_field] }}
      </li>
    </ul>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";
import { useI18n } from "vue-i18n";
export default {
  props: [
    "select_dd",
    "data",
    "select_label",
    "data_field",
    "defaultValue",
    "select_width",
    "selected_value",
  ],
  setup(props, ctx) {
    console.log(props.defaultValue);
    const { t } = useI18n();
    const state = reactive({
      uiSelectClass: "",
    });
    state.label = computed(() => props.selected_value || t("message.select"));
    function selectedOption(select) {
      if (select != null)
        state.label = computed(() => select[props.data_field]);
      else state.label = t("message.select");
      state.uiSelectClass = "";
      ctx.emit("get", select);
    }
    return { state, selectedOption };
  },
};
</script>
