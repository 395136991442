<template>
  <transition name="slide">
    <div :class="['notification', notification.color]" v-if="notification.show">
      <p class="notification-text text-center">
        {{ notification.messsage }}
      </p>
    </div>
  </transition>
</template>

<script>
import { computed } from "@vue/runtime-core";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    const notification = computed(() => store.state.notification.notification);
    return {
      notification,
    };
  },
};
</script>
