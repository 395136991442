<template>
  <Popup :header="$t('message.new_code')">
    <div class="form">
      <div class="grid">
        <Dropdown
          @get="(val) => selectCode(val)"
          :select_label="$t('message.select_client')"
          select_dd="client_dd"
          data_field="company_name"
          :data="clients"
        ></Dropdown>
        <div v-if="form.showClientAddMsg" class="errors error-text">
          {{ $t("message.please_add_client") }}
        </div>
        <div class="clearfix"></div>
        <br />
      </div>
      <div class="clearfix"></div>
      <br />
      <div class="grid gap d" style="--c: 1fr 2fr">
        <div class="form-group">
          <input
            type="text"
            :class="[
              'form-input ',
              form.new_code ? 'focused' : '',
              form.is_searching && searchedCodes.length && form.new_code
                ? 'form-input__searching'
                : '',
            ]"
            v-focus
            v-model="form.new_code"
            :placeholder="$t('message.new_code')"
            @keyup="form.is_searching = true"
          />
          <label>{{ $t("message.new_code") }}</label>
          <ul
            class="form-dropdown form-group__dropdown w-400 mh-250 oa"
            v-if="searchedCodes.length && form.new_code && form.is_searching"
          >
            <li v-for="(code, key) in searchedCodes" :key="key">
              <a
                @click="assignCode(code)"
                @keyup.enter="assignCode(code)"
                tabindex="0"
                >{{ `${code.code} - ${code.description}` }}</a
              >
            </li>
          </ul>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-input"
            v-focus
            :placeholder="$t('message.company')"
            v-model="form.company_name"
          />
          <label>{{ $t("message.company") }}</label>
        </div>
      </div>
      <div class="clearfix"></div>
      <br />
      <br />
      <div class="grid">
        <div class="form-group">
          <input
            type="text"
            class="form-input focused"
            :placeholder="$t('message.company_code')"
            readonly
            v-model="companyCode"
          />
          <label>{{ $t("message.company_code") }}</label>
        </div>
      </div>
      <div class="clearfix"></div>
      <br />
      <button class="form-btn" v-if="!form.loading" @click="addEditCode">
        {{ mode === "create" ? $t("message.create") : $t("message.update") }}
      </button>
      <button class="form-btn disabled" disabled v-if="form.loading">
        {{ mode === "create" ? "Saving..." : "Updating..." }}
      </button>
      <!-- <div class="clearfix"></div> -->
      <!-- <br /> -->
      <!-- <h3 class="title">*Instructions for adding code</h3>
      <p class="txt-gray">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi deleniti
        deserunt consequatur officiis exercitationem possimus reiciendis impedit
        ea a ipsam, sed, consequuntur perspiciatis facilis esse commodi nostrum
        ducimus odit. Assumenda.
      </p> -->
    </div>
  </Popup>
</template>

<script>
// import { ref } from '@vue/reactivity'
import Popup from "@/components/Popup.vue";
import { computed, onMounted, reactive, ref } from "@vue/runtime-core";
import helper from "@/helper.js";
import { useStore } from "vuex";
import Dropdown from "@/components/element/Dropdown.vue";
import router from "@/router";
export default {
  components: { Popup, Dropdown },
  props: ["mode", "payload"],
  setup(props) {
    // console.log(props.payload);
    const store = useStore();
    const form = ref({
      loading: false,
      new_code: null,
      is_searching: false,
      showClientAddMsg: false,
    });
    const state = reactive({
      codes: [411, 711, 606, 512],
      uiSelectClass: "",
    });
    const emitter = helper().emitter();
    const codes = computed(() => store.state.code.codes);
    const clients = computed(() => store.state.client.clients);
    const searchedCodes = computed(() => {
      return store.state.code.codes?.filter((c) => {
        return c.code.indexOf(form.value.new_code) != -1;
      });
    });
    const companyCode = computed(() => {
      if (form.value.company_name != undefined) {
        return (
          form.value.new_code +
          form.value.company_name.toLowerCase().replace(/[^\w-]+/g, "")
        );
      }
    });
    // const popupTriggers = ref({
    //   trigger: false
    // })
    function selectCode(obj) {
      form.value.client_id = obj.id;
    }

    function addEditCode() {
      form.value.loading = true;
      form.value.mode = props.mode;
      // console.log(form.value.client_id);
      form.value.company_code = companyCode.value;
      if (form.value.client_id != undefined) {
        // this.form.showClientAddMsg = false;
        store.dispatch("code/addEditCode", form.value).then((res) => {
          form.value.loading = false;
          store.commit("notification/SEND_NOTIFICATION", res);

          // store.state.code.codes.map((codes) => {
          //   if (codes == props.payload) {
          //     codes["added"] = true;
          //   }
          // });
          emitter.emit("close-popup");
          router.push({ name: "MyCodes" });
        });
      } else {
        form.value.loading = false;
        this.form.showClientAddMsg = true;
      }
    }
    function assignCode(code) {
      form.value.new_code = code.code;
      form.value.is_searching = false;
    }
    onMounted(async () => {
      if (!store.state.client.clients.length) {
        await store.dispatch("client/fetchAllClients");
      }
      emitter.emit("model-update");
      if (!codes.value?.data?.length) {
        // const data = {
        //   isPaginate: true,
        // };
        await store.dispatch("code/fetchOhadaCodes");

      }
      if (props.mode === "edit") {
        form.value = props.payload;
        form.value.new_code = props.payload.company_code.split("-")[0];
      }
    });

    // const togglePopup = (trigger) => {
    //   popupTriggers.value['trigger'] = trigger
    // }
    // function updateModel() {
    // }

    return {
      // Popup,
      // popupTriggers,
      // togglePopup,
      // updateModel,
      // activePopup
      state,
      clients,
      codes,
      searchedCodes,
      companyCode,
      assignCode,
      addEditCode,
      selectCode,
      form,
    };
  },
};
</script>
