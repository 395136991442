<template>
  <div class="sidebar">
    <ul class="sidebar-list">
      <li router-name="Dashboard">
        <router-link to="/dashboard">
          <svg
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            aria-labelledby="dashboardIconTitle"
          >
            <title id="dashboardIconTitle">Dashboard</title>
            <rect width="20" height="20" x="2" y="2"></rect>
            <path d="M11 7L17 7M11 12L17 12M11 17L17 17"></path>
            <line x1="7" y1="7" x2="7" y2="7"></line>
            <line x1="7" y1="12" x2="7" y2="12"></line>
            <line x1="7" y1="17" x2="7" y2="17"></line></svg
          >&nbsp;<span>{{ $t("message.dashboard") }}</span></router-link
        >
      </li>
      <li router-name="MyClients|ArchivedClients">
        <a href="javascript:;" class="has-dropdown" dropdown="client_dropdown">
          <svg
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            aria-labelledby="personIconTitle"
          >
            <title id="personIconTitle">Person</title>
            <path
              d="M4,20 C4,17 8,17 10,15 C11,14 8,14 8,9 C8,5.667 9.333,4 12,4 C14.667,4 16,5.667 16,9 C16,14 13,14 14,15 C16,17 20,17 20,20"
            ></path></svg
          >&nbsp;<span>{{ $t("message.clients") }}</span>
        </a>
        <ul
          class="dropdown"
          dropdown-status="closed"
          dropdown-height
          id="client_dropdown"
        >
          <li>
            <a href="javascript:;" @click="togglePopup('client', 'create')">{{
              $t("message.new_client")
            }}</a>
            <ClientFormPopup
              v-if="popupTriggers.popup == 'client'"
              mode="create"
              feature="Client"
            ></ClientFormPopup>
            <!-- <Popup v-if="popupTriggers.trigger == 'server'" header="`Create `new client">
              <h1>Popup 2</h1>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ducimus accusamus similique harum? Quas perspiciatis adipisci itaque molestiae, quam accusamus deleniti. Qui, repudiandae aliquid! Consectetur sequi architecto distinctio dignissimos expedita maxime.
            </Popup> -->
          </li>
          <li>
            <!-- <a href="javascript:;">My Clients</a> -->
            <router-link to="/my-clients" :class="isActive ? 'yes' : 'no'">{{
              $t("message.my_clients")
            }}</router-link>
          </li>
          <li>
            <router-link to="/clients/archived">{{
              $t("message.archived")
            }}</router-link>
          </li>
        </ul>
      </li>
      <li router-name="MySuppliers|ArchivedSuppliers">
        <a
          href="javascript:;"
          class="has-dropdown"
          dropdown="supplier_dropdown"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-users"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#2c3e50"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <circle cx="9" cy="7" r="4" />
            <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" /></svg
          >&nbsp;<span>{{ $t("message.suppliers") }}</span>
        </a>
        <ul
          class="dropdown"
          dropdown-status="closed"
          dropdown-height
          id="supplier_dropdown"
        >
          <li>
            <a href="javascript:;" @click="togglePopup('supplier', 'create')">{{
              $t("message.new_supplier")
            }}</a>
            <ClientFormPopup
              v-if="popupTriggers.popup == 'supplier'"
              mode="create"
              feature="Supplier"
            ></ClientFormPopup>
          </li>
          <li>
            <router-link to="/my-suppliers" :class="isActive ? 'yes' : 'no'">{{
              $t("message.my_suppliers")
            }}</router-link>
          </li>
          <li>
            <router-link to="/suppliers/archived">{{
              $t("message.archived")
            }}</router-link>
          </li>
        </ul>
      </li>
      <li router-name="MyEmployees|ArchivedEmployees">
        <a
          href="javascript:;"
          class="has-dropdown"
          dropdown="employee_dropdown"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-user-plus"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#2c3e50"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <circle cx="9" cy="7" r="4" />
            <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
            <path d="M16 11h6m-3 -3v6" /></svg
          >&nbsp;<span>{{ $t("message.employees") }}</span>
        </a>
        <ul
          class="dropdown"
          dropdown-status="closed"
          dropdown-height
          id="employee_dropdown"
        >
          <li>
            <a href="javascript:;" @click="togglePopup('employee', 'create')">{{
              $t("message.new_employee")
            }}</a>
            <ClientFormPopup
              v-if="popupTriggers.popup == 'employee'"
              mode="create"
              feature="Employee"
            ></ClientFormPopup>
          </li>
          <li>
            <router-link to="/my-employees" :class="isActive ? 'yes' : 'no'">{{
              $t("message.my_employees")
            }}</router-link>
          </li>
          <li>
            <router-link to="/employees/archived">{{
              $t("message.archived")
            }}</router-link>
          </li>
        </ul>
      </li>
      <li router-name="MyCodes">
        <a href="javascript:;" class="has-dropdown" dropdown="code_dropdown">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="codeIconTitle"
          >
            <title id="codeIconTitle">Code</title>
            <path d="M7 8L3 12L7 16"></path>
            <path d="M17 16L21 12L17 8"></path></svg
          >&nbsp;<span>{{ $t("message.codes") }}</span>
        </a>
        <ul
          class="dropdown"
          dropdown-status="closed"
          dropdown-height
          id="code_dropdown"
        >
          <li>
            <a href="javascript:;" @click="togglePopup('code', 'create')">{{
              $t("message.new_code")
            }}</a>
            <CodeFormPopup
              v-if="popupTriggers.popup == 'code'"
              mode="create"
            ></CodeFormPopup>
          </li>
          <li>
            <router-link to="/my-codes" :class="isActive ? 'yes' : 'no'">{{
              $t("message.my_codes")
            }}</router-link>
          </li>
        </ul>
      </li>
      <li>
        <a href="javascript:;" class="has-dropdown" dropdown="list_dropdown">
          <svg
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            aria-labelledby="justifyIconTitle"
          >
            <title id="justifyIconTitle">Justify</title>
            <path d="M6 10L18 10M6 6L18 6M6 14L18 14M6 18L18 18"></path></svg
          >&nbsp;<span>{{ $t("message.list") }}</span>
        </a>
        <ul
          class="dropdown"
          dropdown-status="closed"
          dropdown-height
          id="list_dropdown"
        >
          <li>
            <a href="javascript:;">{{ $t("message.journals") }}</a>
          </li>
          <li>
            <router-link to="/journal/ledger/third">{{
              $t("message.ledger_of_third")
            }}</router-link>
          </li>
          <li>
            <router-link to="/journal/ledger/general">{{
              $t("message.general_ledger")
            }}</router-link>
          </li>
          <li>
            <a href="javascript:;">{{ $t("message.balance") }}</a>
          </li>
        </ul>
      </li>
      <li>
        <a href="javascript:;">
          <svg
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            aria-labelledby="fileIconTitle"
          >
            <title id="fileIconTitle">File</title>
            <path stroke-linecap="round" d="M13 3v6h6"></path>
            <path d="M13 3l6 6v12H5V3z"></path></svg
          >&nbsp;<span>{{ $t("message.tax_return") }}</span></a
        >
      </li>
      <li router-name="ManageAccount|BusinessSettings|MyAccount">
        <a href="javascript:;" class="has-dropdown" dropdown="account_dropdown">
          <svg
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            aria-labelledby="userIconTitle"
          >
            <title id="userIconTitle">User</title>
            <path
              stroke-linecap="round"
              d="M5.5,19.5 C7.83333333,18.5 9.33333333,17.6666667 10,17 C11,16 8,16 8,11 C8,7.66666667 9.33333333,6 12,6 C14.6666667,6 16,7.66666667 16,11 C16,16 13,16 14,17 C14.6666667,17.6666667 16.1666667,18.5 18.5,19.5"
            ></path>
            <circle cx="12" cy="12" r="10"></circle></svg
          >&nbsp;<span>{{ $t("message.accounts") }}</span>
        </a>
        <ul
          class="dropdown"
          dropdown-status="closed"
          dropdown-height
          id="account_dropdown"
        >
          <li>
            <router-link to="/account/manage">{{
              $t("message.manage_account")
            }}</router-link>
          </li>
          <li>
            <router-link to="/account/business-settings">{{
              $t("message.business_settings")
            }}</router-link>
          </li>
          <li>
            <router-link to="/account/profile">{{
              $t("message.my_account")
            }}</router-link>
          </li>
        </ul>
      </li>
      <!-- <li>
        <a href="javascript:;">
          <svg
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            aria-labelledby="newIconTitle"
          >
            <title id="newIconTitle">New</title>
            <path d="M19 14V22H2.99997V4H13"></path>
            <path
              d="M17.4608 4.03921C18.2418 3.25817 19.5082 3.25816 20.2892 4.03921L20.9608 4.71079C21.7418 5.49184 21.7418 6.75817 20.9608 7.53921L11.5858 16.9142C11.2107 17.2893 10.702 17.5 10.1716 17.5L7.5 17.5L7.5 14.8284C7.5 14.298 7.71071 13.7893 8.08579 13.4142L17.4608 4.03921Z"
            ></path>
            <path d="M16.25 5.25L19.75 8.75"></path></svg
          >&nbsp;<span>Manage Account</span></a
        >
      </li>
      <li>
        <a href="javascript:;">
          <svg
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            aria-labelledby="settingsIconTitle"
          >
            <title id="settingsIconTitle">Settings</title>
            <pathrouter
              d="M5.03506429,12.7050339 C5.01187484,12.4731696 5,12.2379716 5,12 C5,11.7620284 5.01187484,11.5268304 5.03506429,11.2949661 L3.20577137,9.23205081 L5.20577137,5.76794919 L7.9069713,6.32070904 C8.28729123,6.0461342 8.69629298,5.80882212 9.12862533,5.61412402 L10,3 L14,3 L14.8713747,5.61412402 C15.303707,5.80882212 15.7127088,6.0461342 16.0930287,6.32070904 L18.7942286,5.76794919 L20.7942286,9.23205081 L18.9649357,11.2949661 C18.9881252,11.5268304 19,11.7620284 19,12 C19,12.2379716 18.9881252,12.4731696 18.9649357,12.7050339 L20.7942286,14.7679492 L18.7942286,18.2320508 L16.0930287,17.679291 C15.7127088,17.9538658 15.303707,18.1911779 14.8713747,18.385876 L14,21 L10,21 L9.12862533,18.385876 C8.69629298,18.1911779 8.28729123,17.9538658 7.9069713,17.679291 L5.20577137,18.2320508 L3.20577137,14.7679492 L5.03506429,12.7050339 Z"
            ></pathrouter>
            <circle cx="12" cy="12" r="1"></circle></svg
          >&nbsp;<span>Businees Settings</span></a
        >
      </li> -->
      <!-- <li>
        <a href="javascript:;">
          <svg
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            aria-labelledby="userIconTitle"
          >
            <title id="userIconTitle">User</title>
            <path
              stroke-linecap="round"
              d="M5.5,19.5 C7.83333333,18.5 9.33333333,17.6666667 10,17 C11,16 8,16 8,11 C8,7.66666667 9.33333333,6 12,6 C14.6666667,6 16,7.66666667 16,11 C16,16 13,16 14,17 C14.6666667,17.6666667 16.1666667,18.5 18.5,19.5"
            ></path>
            <circle cx="12" cy="12" r="10"></circle></svg
          >&nbsp;<span>My Account</span></a
        >
      </li> -->
      <li>
        <a href="javascript:;">
          <svg
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            aria-labelledby="supportIconTitle"
          >
            <title id="supportIconTitle">Support</title>
            <path
              d="M18,9 L16,9 C14.8954305,9 14,9.8954305 14,11 L14,13 C14,14.1045695 14.8954305,15 16,15 L16,15 C17.1045695,15 18,14.1045695 18,13 L18,9 C18,4.02943725 13.9705627,0 9,0 C4.02943725,0 0,4.02943725 0,9 L0,13 C1.3527075e-16,14.1045695 0.8954305,15 2,15 L2,15 C3.1045695,15 4,14.1045695 4,13 L4,11 C4,9.8954305 3.1045695,9 2,9 L0,9"
              transform="translate(3 3)"
            ></path>
            <path
              d="M21,14 L21,18 C21,20 20.3333333,21 19,21 C17.6666667,21 16,21 14,21"
            ></path></svg
          >&nbsp;<span>{{ $t("message.support") }}</span></a
        >
      </li>
    </ul>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import ClientFormPopup from "@/views/system/clients/ClientAddEditPopup.vue";
import CodeFormPopup from "@/views/system/codes/CodeAddEditPopup.vue";
import { onMounted } from "@vue/runtime-core";
import helper from "@/helper.js";
import router from "@/router";
export default {
  components: { ClientFormPopup, CodeFormPopup },
  setup() {
    const emitter = helper().emitter();
    const popupTriggers = ref({
      popup: null,
      mode: "create",
    });
    const isActive = ref(null);

    const togglePopup = async (popup, mode = "create") => {
      popupTriggers.value.popup = popup;
      popupTriggers.value.mode = mode;
    };

    onMounted(() => {
      emitter.on("close-popup", () => {
        popupTriggers.value.popup = null;
      });

      // Toggle Dropdown
      let dropdown = document.querySelectorAll("[dropdown]");
      dropdown.forEach((c) => {
        let dd_attr = c.attributes.dropdown.value;
        c.addEventListener("click", () => {
          c.parentElement.classList.toggle("dropdown-active");
          toggleDropdown(dd_attr);
        });
      });
      function toggleDropdown(dd_attr) {
        let ele = document.getElementById(dd_attr);
        let dropdown_status = ele.attributes["dropdown-status"].value;
        let dropdown_height = ele.attributes["dropdown-height"].value;
        if (ele.id == "code_dropdown") {
          dropdown_height = 95;
        }
        if (dropdown_status == "closed") {
          ele.style.display = "block";
          let client_height = ele.clientHeight;
          if (!dropdown_height) {
            ele.setAttribute("dropdown-height", client_height);
            ele.style.height = 0;
            setTimeout(() => {
              ele.style.height = client_height + "px";
            }, 0.1);
          } else {
            ele.style.height = dropdown_height + "px";
          }
          ele.setAttribute("dropdown-status", "active");
        } else if (dropdown_status == "active") {
          setTimeout(() => {
            ele.style.display = "none";
          }, 300);
          ele.setAttribute("dropdown-status", "closed");
          ele.style.height = 0;
        }
      }

      // Select/open current dropdown on page load.
      let all_routers = document.querySelectorAll("[router-name]");
      all_routers.forEach((r) => {
        const attr = r.getAttribute("router-name").split("|");
        if (attr.includes(router.currentRoute.value.name)) {
          r.children[0].click();
        }
      });
    });

    return {
      popupTriggers,
      togglePopup,
      isActive,
    };
  },
};
</script>
