<template>
    <div class="layout">
  <div>
      <Header></Header>
      <div class="main">
        <Sidebar />
        <div :class="['content', is_loading ? 'is-loading' : '']" id="content">
          <Loader v-if="is_loading"></Loader>
          <Notification />
          <slot />
        </div>
      </div>
      <div class="footer">Footer</div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/scss/style.scss";
</style>

<script>
import Header from "@/views/system/include/Header.vue";
import Sidebar from "@/views/system/include/Sidebar.vue";
import { computed, onMounted, ref } from "@vue/runtime-core";
import helper from "@/helper.js";
import Notification from "@/components/Notification.vue";
import Loader from "@/components/Loader.vue";
import { useStore } from "vuex";
export default {
  name: "SystemLayout",
  components: { Header, Sidebar, Notification, Loader },
  setup() {
    const emitter = helper().emitter();
    const popupTriggers = ref({
      trigger: null,
    });

    const store = useStore();
    const is_loading = computed(() => store.state.component.loader.is_loading);
    onMounted(() => {
      // let grp = document.querySelectorAll('.form-input');
      // grp.forEach(e => {
      //   console.log(e)
      // })
      // Toggle Popup
      emitter.on("model-update", (trigger) => {
        popupTriggers.value["trigger"] = trigger;
      });
    });

    return {
      popupTriggers,
      is_loading,
    };
  },
};
</script>
